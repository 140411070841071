import PropTypes from 'prop-types'
import {createSelector} from 'reselect'
import keyBy from 'lodash/keyBy.js'
import get from 'lodash/get.js'
import sortBy from 'lodash/sortBy.js'

import {getState, setForm, formsSelector} from '../store.js'
import apiverson from '../common/apiverson.js'
import {formatAbodeURL} from '../common/abode.js'
import {TagsShape, CommentShape} from '../common/PropTypes.js'
import {hasMOPermissionSelector} from '../data/me.js'
import {
  hasUseMOsFeatureSelector,
  showFeatureLocksSelector,
} from '../data/company.js'
import {
  productsSelector,
  ensureProductsLoaded,
  ensureLinkedSKUsLoaded,
} from '../data/products.js'
import {warehousesSelector} from './warehouses.js'
import {dateTimeFormat} from '../common/date.js'

export const MOS = 'MOS'

export const MOLineShape = PropTypes.shape({
  _link: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  created_date: PropTypes.string.isRequired,
  updated_date: PropTypes.string.isRequired,
  requested_quantity: PropTypes.number.isRequired,
  finished_quantity: PropTypes.number.isRequired,
  internal_notes: PropTypes.string,
  manufacturer_notes: PropTypes.string,
  sku: PropTypes.string.isRequired,
})

export const MOShape = PropTypes.shape({
  status: PropTypes.string.isRequired,
  reference_id: PropTypes.string.isRequired,
  created_date: PropTypes.string.isRequired,
  updated_date: PropTypes.string.isRequired,
  warehouse_id: PropTypes.number.isRequired,
  internal_notes: PropTypes.string,
  manufacturer_notes: PropTypes.string,
  lines: PropTypes.arrayOf(MOLineShape).isRequired,
  tags: TagsShape.isRequired,
  comments: PropTypes.arrayOf(CommentShape).isRequired,
})

export function setMOs(mos) {
  setForm(MOS, keyBy(mos, 'reference_id'))
}

export function setMO(mo) {
  const mos = mosSelector(getState())

  setForm(MOS, {...mos, [mo.reference_id]: mo})
}

export function mosSelector(state) {
  return formsSelector(state)[MOS] || mosSelector.default
}
mosSelector.default = {}

export function moSelector(state, {referenceID}) {
  return mosSelector(state)[referenceID]
}

export const createMOSelector = (referenceID) =>
  createSelector(mosSelector, (mos) => mos[referenceID])

export function hasMOFeatureSelector(state) {
  return hasUseMOsFeatureSelector(state)
}

export const canUseMOsSelector = createSelector(
  hasMOPermissionSelector,
  hasMOFeatureSelector,
  (hasPermission, hasFeature) => hasPermission && hasFeature,
)

export function showMONavSelector(state) {
  const showFeatureLocks = showFeatureLocksSelector(state)
  const hasMOFeature = hasMOFeatureSelector(state)
  const hasMOPermission = hasMOPermissionSelector(state)

  return hasMOPermission && (hasMOFeature || showFeatureLocks)
}

export function moStatusSelector(state, {referenceID}) {
  const mo = moSelector(state, {referenceID})

  return mo && mo.status
}

export function moLinesSelector(state, {referenceID}) {
  const mo = moSelector(state, {referenceID})

  return get(mo, 'lines', [])
}

export function moLineSelector(state, {referenceID, lineID}) {
  const lines = moLinesSelector(state, {referenceID})

  return lines.find(({id}) => id === lineID)
}

export function commentsSelector(state, {referenceID}) {
  const mo = moSelector(state, {referenceID})

  return get(mo, 'comments', [])
}

export function getCommentGroups(mo) {
  const comments = get(mo, 'comments', [])
  const daysIndex = {}

  return sortBy(comments, 'date')
    .reverse()
    .reduce((days, comment) => {
      const day = dateTimeFormat(comment.date)

      if (daysIndex[day]) {
        daysIndex[day].comments.push(comment)
      } else {
        daysIndex[day] = {
          day,
          comments: [comment],
        }

        days.push(daysIndex[day])
      }

      return days
    }, [])
}

export const createMOCommentGroupsSelector = (moSelector) =>
  createSelector(moSelector, (mo) => getCommentGroups(mo))

function getWarehouseID(mo) {
  return get(mo, 'warehouse_id', null)
}

export function moWarehouseIDSelector(state, {referenceID}) {
  const mo = moSelector(state, {referenceID})

  return getWarehouseID(mo)
}

export const createMOLinesSelector = (moSelector) =>
  createSelector(moSelector, (mo) => {
    return get(mo, 'lines', [])
  })

export const createMOTotalSKUCountSelector = (moSelector) =>
  createSelector(moSelector, (mo) => {
    const lines = get(mo, 'lines', [])

    return Object.keys(
      lines.reduce((prev, {sku}) => {
        prev[sku] = true

        return prev
      }, {}),
    ).length
  })

export const createMOTotalLineQuantitySelector = (moSelector) =>
  createSelector(moSelector, (mo) => {
    const lines = get(mo, 'lines', [])

    return lines.reduce(
      (prev, {requested_quantity}) => prev + requested_quantity,
      0,
    )
  })

export function manufacturerNotesSelector(state, {referenceID}) {
  const mo = moSelector(state, {referenceID})

  return get(mo, 'manufacturer_notes')
}

export function internalNotesSelector(state, {referenceID}) {
  const mo = moSelector(state, {referenceID})

  return get(mo, 'internal_notes')
}

export const createMOWarehouseSelector = (moSelector) =>
  createSelector(moSelector, warehousesSelector, (mo, warehouses) => {
    const warehouseID = get(mo, 'warehouse_id')

    return get(warehouses, warehouseID, {id: warehouseID, address: {}})
  })

export const createMOCreatedDateSelector = (moSelector) =>
  createSelector(moSelector, (mo) => get(mo, 'created_date', ''))

export const createMOUpdatedDateSelector = (moSelector) =>
  createSelector(moSelector, (mo) => get(mo, 'updated_date', ''))

export const createMOTagsSelector = (moSelector) =>
  createSelector(moSelector, (mo) => get(mo, 'tags', []))

export function getProductSKUsFromMOs(mos) {
  return Object.keys(
    mos.reduce((prev, mo) => {
      mo.lines.forEach((line) => {
        prev[line.sku] = true
      })

      return prev
    }, {}),
  ).filter((sku) => sku)
}

export function moProductsSelector(state, {referenceID}) {
  const mo = moSelector(state, {referenceID})

  if (!mo) {
    return {}
  }

  const productSKUs = getProductSKUsFromMOs([mo])
  const products = productsSelector(state)

  return productSKUs.reduce((prev, sku) => {
    if (products[sku]) {
      return {
        ...prev,
        [sku]: products[sku],
      }
    }
    return prev
  }, {})
}

export function patchMOTag(referenceID, moTag, needsAdding = true) {
  const mo = moSelector(getState(), {referenceID})

  if (!mo) {
    return
  }

  const tags = mo.tags.filter(({id}) => id !== moTag.id)

  if (needsAdding) {
    tags.push(moTag)
  }

  setMO({...mo, tags})
}

export async function ensureMO(referenceID, {reload} = {}) {
  let mo = moSelector(getState(), {referenceID})

  if (!reload && mo) {
    return mo
  }

  const {json} = await apiverson.get(
    `/manufacturing_order/${encodeURIComponent(referenceID)}`,
  )

  setMO(json)

  return json
}

export async function setMOStateAndEnsureProductsLoaded(mo) {
  setMO(mo)

  const skus = getProductSKUsFromMOs([mo])

  await ensureProductsLoaded(skus)

  await ensureLinkedSKUsLoaded(skus)
}

export async function setMOsStateAndEnsureProductsLoaded(mos) {
  setMOs(mos)

  await ensureProductsLoaded(getProductSKUsFromMOs(mos))
}

export function printURLSelector(state, {referenceIDs}) {
  return formatAbodeURL('/mo', {reference_id: referenceIDs})
}
