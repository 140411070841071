import {createSelector} from 'reselect'
import keyBy from 'lodash/keyBy.js'
import get from 'lodash/get.js'
import sortBy from 'lodash/sortBy.js'
import moment from 'moment'

import api, {formatAPIURL} from '../common/api.js'
import {hasPurchaseOrderPermissionSelector} from '../data/me.js'
import {
  showFeatureLocksSelector,
  usePurchaseOrdersSelector,
} from '../data/company.js'
import {
  STATUS_FILTERS,
  UNSENT_STATUS,
  SENT_STATUS,
  PARTIAL_STATUS,
  RECEIVED_STATUS,
  CANCELLED_STATUS,
} from '../common/constants/PurchaseOrders.js'
import {warehousesSelector} from '../data/warehouses.js'
import {suppliersSelector, supplierSelector} from '../data/suppliers.js'
import {productSelector, ensureProductsLoaded} from '../data/products.js'
import {COMMUNICATION_EMAIL} from '../common/constants/Suppliers.js'
import {getState, setForm, formsSelector} from '../store.js'
import {buildPath} from '../common/querystring.js'

export const POS = 'POS'

export function setPOs(pos) {
  setForm(POS, keyBy(pos, 'po_id'))
}

export function setPO(po) {
  const pos = posSelector(getState())

  setForm(POS, {...pos, [po.po_id]: po})
}

export function posSelector(state) {
  return formsSelector(state)[POS] || posSelector.default
}
posSelector.default = {}

export function poSelector(state, {poID}) {
  return posSelector(state)[poID]
}

export const createPurchaseOrderSelector = (poID) =>
  createSelector(posSelector, (purchaseOrders) => purchaseOrders[poID])

export const canUsePurchaseOrdersSelector = createSelector(
  hasPurchaseOrderPermissionSelector,
  usePurchaseOrdersSelector,
  (hasPermission, usePurchaseOrders) => hasPermission && usePurchaseOrders,
)

export function showPurchaseOrderNavSelector(state) {
  const showFeatureLocks = showFeatureLocksSelector(state)
  const usePurchaseOrders = usePurchaseOrdersSelector(state)
  const hasPurchaseOrderPermission = hasPurchaseOrderPermissionSelector(state)

  return hasPurchaseOrderPermission && (usePurchaseOrders || showFeatureLocks)
}

export function poStatusSelector(state, {poID}) {
  const po = poSelector(state, {poID})

  return getPOStatus(po)
}

export function statusDisplaySelector(state, {poID}) {
  const status = poStatusSelector(state, {poID})

  const statusObj = STATUS_FILTERS.find(({value}) => status === value)

  return statusObj ? statusObj.display : ''
}

export function getPOStatus(po) {
  if (!po) {
    return null
  }

  if (isPOCancelled(po)) {
    return CANCELLED_STATUS
  }

  return po.status
}

export function isPOCancelled(po) {
  if (!po) {
    return false
  }

  return po.status === CANCELLED_STATUS || !!po.cancelled_date
}

export function itemsSelector(state, {poID}) {
  const po = poSelector(state, {poID})

  return get(po, 'items', [])
}

export function productTotalSelector(state, {poID}) {
  const items = itemsSelector(state, {poID})

  return items.reduce((prev, item) => {
    const price = get(item, 'unit_price', 0)
    const discountAmount = get(item, 'discount_amount', 0)

    return item.quantity * price - discountAmount + prev
  }, 0)
}

export const discountAmountSelector = createSelector(poSelector, (po) =>
  get(po, 'discount_amount', 0),
)

export const shippingAmountSelector = createSelector(poSelector, (po) =>
  get(po, 'shipping_amount', 0),
)

export const taxAmountSelector = createSelector(poSelector, (po) =>
  get(po, 'tax_amount', 0),
)

export const grandTotalSelector = createSelector(
  productTotalSelector,
  discountAmountSelector,
  shippingAmountSelector,
  taxAmountSelector,
  (productTotal, discountAmount, shippingAmount, taxAmount) =>
    productTotal - discountAmount + shippingAmount + taxAmount,
)

export function commentsSelector(state, {poID}) {
  const po = poSelector(state, {poID})

  return get(po, 'comments', [])
}

export function getProductSKUsFromPurchaseOrder(po) {
  if (!po) {
    return []
  }

  return po.items.map((item) => get(item, 'product.sku'))
}

export function getAllCommentsSelector(po) {
  if (!po) {
    return []
  }

  const comments = get(po, 'comments', []).slice(0)
  const goodsReceipts = get(po, 'goods_receipts', [])
  const {created, sent} = po

  comments.push({
    text: 'Purchase Order created',
    date: created,
    user: null,
  })

  if (sent) {
    comments.push({
      text: 'Purchase Order sent',
      date: sent,
      user: null,
    })
  }

  goodsReceipts.forEach((goodsReceipt) => {
    getGoodsReceiptComments(goodsReceipt).forEach((comment) =>
      comments.push(comment),
    )
  })

  return comments
}

export function allCommentsSelector(state, {poID}) {
  const po = poSelector(state, {poID})

  return getAllCommentsSelector(po)
}

function getGoodsReceiptComments(goodsReceipt) {
  const comments = get(goodsReceipt, 'comments', []).slice(0)
  const {goods_receipt_id: id, created, received} = goodsReceipt

  comments.push({
    text: `Goods Receipt ${id} created`,
    date: created,
    user: null,
  })

  if (received) {
    comments.push({
      text: `Goods Receipt ${id} received`,
      date: received,
      user: null,
    })
  }

  return comments
}

export function getCommentGroups(comments) {
  const daysIndex = {}

  return sortBy(comments, 'date')
    .reverse()
    .reduce((days, comment) => {
      const day = moment(comment.date).format('MMM D, YYYY')

      if (daysIndex[day]) {
        daysIndex[day].comments.push(comment)
      } else {
        daysIndex[day] = {
          day,
          comments: [comment],
        }

        days.push(daysIndex[day])
      }

      return days
    }, [])
}

export function commentGroupsSelector(state, {poID}) {
  const comments = allCommentsSelector(state, {poID})

  return getCommentGroups(comments)
}

export const createPOCommentGroupsSelector = (poID) =>
  createSelector(
    (state) => poSelector(state, {poID}),
    (po) => getCommentGroups(getAllCommentsSelector(po)),
  )

export function canEditIDSelector(state, {poID}) {
  const po = poSelector(state, {poID})

  return getPOStatus(po) === UNSENT_STATUS
}

export function shipToAddressSelector(state, {poID}) {
  const po = poSelector(state, {poID})

  return get(po, 'warehouse.address', {})
}

export function supplierNameSelector(state, {poID}) {
  const po = poSelector(state, {poID})

  return get(po, 'supplier.address.company', '')
}

export function instructionsSelector(state, {poID}) {
  const po = poSelector(state, {poID})

  return get(po, 'instructions', '')
}

export function updatedDateSelector(state, {poID}) {
  const po = poSelector(state, {poID})

  return po && po.updated
}

export function dateCreatedSelector(state, {poID}) {
  const po = poSelector(state, {poID})

  return po && po.created
}

export function dateSentSelector(state, {poID}) {
  const po = poSelector(state, {poID})

  return po && po.sent
}

export function printURLSelector(state, {poID}) {
  return formatAPIURL(`/purchase_order/${encodeURIComponent(poID)}/send/`)
}

export function warehouseIDSelector(state, {poID}) {
  const po = poSelector(state, {poID})

  return get(po, 'warehouse.id', null)
}

export function supplierIDSelector(state, {poID}) {
  const po = poSelector(state, {poID})

  return get(po, 'supplier.id', null)
}

export function currentSupplierSelector(state, {poID}) {
  const supplierID = supplierIDSelector(state, {poID})
  const supplier = supplierSelector(state, {supplierID})
  return supplier || null
}

export function shippingMethodSelector(state, {poID}) {
  const po = poSelector(state, {poID})

  return get(po, 'shipping_method', '')
}

export function paymentMethodSelector(state, {poID}) {
  const po = poSelector(state, {poID})

  return get(po, 'payment_method', '')
}

function goodsReceiptsSelector(state, {poID}) {
  const po = poSelector(state, {poID})

  return get(po, 'goods_receipts', [])
}

export function activeGoodsReceiptsSelector(state, {poID}) {
  return goodsReceiptsSelector(state, {poID}).filter(
    ({status}) => status !== 'deleted',
  )
}

export function receivedCountsByPOItemIDSelector(state, {poID}) {
  const goodsReceipts = activeGoodsReceiptsSelector(state, {poID})
  const items = itemsSelector(state, {poID})
  const received = {}

  items.forEach(({id}) => {
    received[id] = 0
  })

  goodsReceipts.forEach((goodsReceipt) => {
    var grItems = goodsReceipt.items

    grItems.forEach((grItem) => {
      received[grItem.po_item_id] += grItem.quantity
    })
  })

  return received
}

export function poItemSelector(state, {poID, poItemID}) {
  const items = itemsSelector(state, {poID})

  return items.find(({id}) => id === poItemID)
}

export function poItemIDsSelector(state, {poID}) {
  const items = itemsSelector(state, {poID})

  return items.map(({id}) => id)
}

export function poItemProductSelector(state, {poID, poItemID}) {
  const item = poItemSelector(state, {poID, poItemID})

  const sku = get(item, 'product.sku')
  return productSelector(state, {sku})
}

export function poItemReceivedSelector(state, {poID, poItemID}) {
  const goodsReceipts = activeGoodsReceiptsSelector(state, {poID})

  return goodsReceipts.reduce(
    (prev, {items}) =>
      get(
        items.find((grItem) => grItem.po_item_id === poItemID),
        'quantity',
        0,
      ) + prev,
    0,
  )
}

export function poItemRemainingSelector(state, {poID, poItemID}) {
  const item = poItemSelector(state, {poID, poItemID})

  const quantity = get(item, 'quantity', 0)
  const received = poItemReceivedSelector(state, {poID, poItemID})

  return quantity - received
}

export function poItemNameSelector(state, {poID, poItemID}) {
  const item = poItemSelector(state, {poID, poItemID})

  return get(item, 'product.name', '')
}

export function poItemSKUSelector(state, {poID, poItemID}) {
  const item = poItemSelector(state, {poID, poItemID})

  return get(item, 'product.sku', '')
}

export function poItemQuantitySelector(state, {poID, poItemID}) {
  const item = poItemSelector(state, {poID, poItemID})

  return get(item, 'quantity', 0)
}

export function poItemLocationInWarehouseSelector(state, {poID, poItemID}) {
  const item = poItemSelector(state, {poID, poItemID})

  return get(item, 'product.warehouse.location_in_warehouse', '')
}

export function poItemSupplierSKUSelector(state, {poID, poItemID}) {
  const item = poItemSelector(state, {poID, poItemID})

  return get(item, 'product.supplier.sku', '')
}

export function poItemPhysicalOnHandSelector(state, {poID, poItemID}) {
  const product = poItemProductSelector(state, {poID, poItemID})
  return get(product, 'total_on_hand', null)
}

export function poItemCommittedSelector(state, {poID, poItemID}) {
  const product = poItemProductSelector(state, {poID, poItemID})
  return get(product, 'total_committed', null)
}

export function poItemPOTotalCommittedSelector(state, {poID, poItemID}) {
  const product = poItemProductSelector(state, {poID, poItemID})

  return get(product, 'po_total_committed', null)
}

export function poItemUnitPriceSelector(state, {poID, poItemID}) {
  const item = poItemSelector(state, {poID, poItemID})

  return get(item, 'unit_price', 0)
}

export function poItemDiscountAmountSelector(state, {poID, poItemID}) {
  const item = poItemSelector(state, {poID, poItemID})

  return get(item, 'discount_amount', 0)
}

export function poItemTotalPriceSelector(state, {poID, poItemID}) {
  const price = poItemUnitPriceSelector(state, {poID, poItemID})
  const quantity = poItemQuantitySelector(state, {poID, poItemID})
  const discountAmount = poItemDiscountAmountSelector(state, {poID, poItemID})

  return price * quantity - discountAmount
}

export function poTotalItemQuantitySelector(state, {poID}) {
  const po = poSelector(state, {poID})

  const items = get(po, 'items', [])

  return items.reduce((prev, {quantity}) => prev + quantity, 0)
}

export function poWarehouseSelector(state, {poID}) {
  const po = poSelector(state, {poID})
  const warehouses = warehousesSelector(state)

  const warehouseID = get(po, 'warehouse.id')

  return get(warehouses, warehouseID, {address: {}})
}

export function poSupplierSelector(state, {poID}) {
  const po = poSelector(state, {poID})
  const suppliers = suppliersSelector(state)

  const supplierID = get(po, 'supplier.id')

  return get(suppliers, supplierID, {address: {}})
}

export function estimatedDeliveryDateSelector(state, {poID}) {
  const po = poSelector(state, {poID})

  return get(po, 'estimated_delivery_date')
}

export function poActionsSelector(state, {poIDs}) {
  const pos = posSelector(state)
  const actions = {
    mark_po_as_sent: true,
    cancel_po: true,
    restore_po: true,
    print_po: true,
    send_po: true,
  }

  for (const poID of poIDs) {
    const po = pos[poID]
    if (!po) {
      continue
    }

    const status = getPOStatus(po)

    if (status === SENT_STATUS) {
      actions.mark_po_as_sent = false
      actions.restore_po = false
    } else if (isPOCancelled(po)) {
      actions.send_po = false
      actions.mark_po_as_sent = false
      actions.print_po = false
      actions.cancel_po = false
    } else if (status === RECEIVED_STATUS) {
      actions.send_po = false
      actions.mark_po_as_sent = false
      actions.cancel_po = false
      actions.restore_po = false
    } else if (status === UNSENT_STATUS) {
      actions.restore_po = false
    } else if (status === PARTIAL_STATUS) {
      actions.restore_po = false
    }
  }

  return actions
}

export function isPOLockedSelector(state, {poID}) {
  const status = poStatusSelector(state, {poID})

  return status === CANCELLED_STATUS
}

export function suppliersWithoutPOEmailSelector(state, {poIDs}) {
  const pos = posSelector(state)

  const supplierWithoutPOEmail = []
  for (const poID of poIDs) {
    const po = pos[poID]

    if (!po) {
      continue
    }

    const supplierID = po.supplier.id

    if (supplierWithoutPOEmail.includes(supplierID)) {
      continue
    }

    const supplier = supplierSelector(state, {supplierID})

    if (!supplier) {
      continue
    }

    if (
      !(
        supplier.po_routing_channel === COMMUNICATION_EMAIL &&
        supplier.po_routing_address
      )
    ) {
      supplierWithoutPOEmail.push(supplier)
    }
  }

  return supplierWithoutPOEmail
}

export function getProductSKUsFromPOs(pos) {
  return Object.keys(
    pos.reduce((prev, po) => {
      po.items.forEach((item) => {
        prev[get(item, 'product.sku', '')] = true
      })

      return prev
    }, {}),
  ).filter((sku) => sku)
}

export function patchPOTag(poID, poTag, needsAdding = true) {
  const po = poSelector(getState(), {poID})

  if (!po) {
    return
  }

  const tags = po.tags.filter(({id}) => id !== poTag.id)

  if (needsAdding) {
    tags.push(poTag)
  }

  setPO({...po, tags})
}

export async function savePO(poID, params) {
  const {json} = await api.put(buildPath(['purchase_order', poID]), params)

  return json
}

export async function ensurePO(poID, {reload} = {}) {
  let po = poSelector(getState(), {poID})

  if (!reload && po) {
    return po
  }

  const {json} = await api.get(buildPath(['purchase_order', poID]))

  setPOStateAndEnsureProductsLoaded(json)

  return json
}

export async function setPOStateAndEnsureProductsLoaded(po) {
  setPO(po)

  const skus = getProductSKUsFromPOs([po])

  await ensureProductsLoaded(skus)
}

export async function setPOsStateAndEnsureProductsLoaded(pos) {
  setPOs(pos)

  await ensureProductsLoaded(getProductSKUsFromPOs(pos))
}
